<template>
  <div class="sb_menu-side">
    <div class="sb_menu-side_items">
      <router-link
        v-for="route in routes"
        :id="`${route.name}_button`"
        :key="route.name"
        class="sb_menu-side_item"
        tag="button"
        :to="{ name: route.name }"
      >
        <div class="sb_menu-side_item_text">
          {{ (route.meta && route.meta.title) || 'NO-META-TITLE' }}
          <!-- <SbBadge
            v-if="route.meta && route.meta.title == 'Inbox' && unreadMessagesCount"
            color="red"
            class="sb_menu-side_item_badge"
          >
            {{ unreadMessagesCount }}
          </SbBadge> -->
          <sb-badge
            v-if="
              route.meta &&
              route.meta.title == 'Organisatie' &&
              pendingAccessRequestsAmount
            "
            color="red"
            class="sb_menu-side_item_badge"
          >
            {{ pendingAccessRequestsAmount }}
          </sb-badge>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SbBadge from '@/components/SbBadge';
import SbSkipTourModal from '@/components/SbSkipTourModal.vue';
import { accessManagerMixin } from '@/mixins/accessManagerMixin';
import { TourStarter } from '@/shepherd/TourStarter';
import { mapActions } from 'vuex';

export default {
  name: 'SbMenuSide',
  components: { SbBadge },
  mixins: [accessManagerMixin],

  props: {
    routes: {
      type: Array,
      default() {
        return [];
      },
    },
    currentRoute: {
      type: Object,
      required: true,
    },
  },

  watch: {
    currentRoute() {
      this.$nextTick(() => {
        this.$emit('close');
      });
    },
  },

  mounted() {
    const currentTourKey = TourStarter.getTourKeyForRole(this.$user.role, [
      'MANAGER_BEHEER',
      'COACH_BEHEER',
    ]);
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
  },
};
</script>

<style lang="scss">
.sb_menu-side {
  position: relative;
  padding: 3rem 0 0 20px;
  background: $brand-lightest-gray;

  &_items {
    position: relative;
    z-index: 2;
    border-top: solid 1px $brand-light-gray;
  }

  &_item {
    position: relative;
    display: block;
    width: 100%;
    padding: 0.8rem 1rem 0.8rem 1.6rem;
    font-size: 1rem;
    white-space: nowrap;
    background: $brand-lightest-gray;
    border-right: solid 1px $brand-light-gray;
    border-bottom: solid 1px $brand-light-gray;
    border-left: solid 1px $brand-light-gray;
    transition: color 0.3s ease, background-color 0.3s ease;

    &_text {
      position: relative;
    }

    &_badge {
      position: absolute !important;
      top: 50%;
      right: 0;
      transform: translateY(-50%) translateX(0);
    }

    &::after {
      position: absolute;
      top: 0;
      left: -1px;
      width: 4px;
      height: 100%;
      content: '';
      background: $brand-primary;
      transition: transform 0.3s ease;
      transform: scaleX(0);
    }

    &.s_active {
      background: $brand-white;
      border-right: solid 1px $brand-white;

      &::after {
        transform: scaleX(1);
      }
    }

    &:hover {
      color: $brand-primary;

      &::after {
        transform: scaleX(1);
      }
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 1px;
    height: 100%;
    content: '';
    background: $brand-light-gray;
  }
}
</style>
