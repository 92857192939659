<template>
  <div class="sb_logo-image">
    <img src="@/assets/logo.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

  .sb_logo-image {
    margin: 0 2rem 0 0;

    img {
      width: 60px;
      height: 60px;
      }
  }

</style>
