<template>
  <div v-if="doRender" class="sb_menu-main" :class="'s_' + environment()">
    <div class="sb_menu-main_left">
      <sb-logo-icon />

      <div class="sb_menu-main_items">
        <template v-for="(route, index) in routes">
          <div
            v-if="shouldShow(route)"
            :id="`sb_menu-main_item_${index}`"
            :key="route.name"
            class="sb_menu-main_item"
            :class="{
              s_active: route.name === currentRoute.name,
              's_has-dropdown': route.subRoutes.length,
            }"
          >
            <sb-badge
              v-if="route.meta && route.meta.badge && badge(route)"
              color="red"
              class="sb_menu-main_badge"
            >
              {{ badge(route) }}
            </sb-badge>
            <router-link
              v-if="!route.subRoutes.length"
              :to="{ name: route.name }"
              tag="button"
              class="sb_menu-main_item_btn"
            >
              {{ (route.meta && route.meta.title) || 'NO-META-TITLE' }}
            </router-link>

            <dropdown v-else placement="bottom-start">
              <router-link
                :to="{ name: route.subRoutes[0].name }"
                class="sb_menu-main_item_btn"
                tag="button"
              >
                {{ (route.meta && route.meta.title) || 'NO-META-TITLE' }}
              </router-link>

              <dropdown-menu slot="list" class="sb_menu-main_item_dropdown">
                <router-link
                  v-for="subRoute in route.subRoutes"
                  :key="subRoute.name"
                  :to="{ name: subRoute.name }"
                  tag="button"
                  class="sb_menu-main_item_dropdown_item"
                >
                  <sb-dropdown-button>
                    {{
                      (subRoute.meta && subRoute.meta.title) || 'NO-META-TITLE'
                    }}
                  </sb-dropdown-button>
                  <sb-badge
                    v-if="
                      subRoute.meta && subRoute.meta.badge && badge(subRoute)
                    "
                    color="red"
                    class="sb_menu-main_badge"
                  >
                    {{ badge(subRoute) }}
                  </sb-badge>
                </router-link>
              </dropdown-menu>
            </dropdown>
          </div>
        </template>
      </div>
    </div>
    <div class="sb_menu-main_right">
      <div
        v-if="$user.role === 'ADMIN'"
        class="sb_menu-main_environment-switcher"
      >
        <template>
          <i-cascader
            v-model="currentEnvironment"
            :data="environmentSwitcherData"
            filterable
            :placeholder="'Bekijk als'"
            not-found-text="Niet gevonden"
            @on-change="$emit('context-change', $event)"
          />
        </template>
      </div>
      <div class="sb_menu-main_user">
        <div class="sb_menu-main_user_dropdown">
          <dropdown placement="bottom-end" trigger="click">
            <!-- <div class="menu-item-with-icon">
              Mijn profiel
              <sb-icon icon-id="icon_cog" />
            </div> -->
            <div class="sb_menu-main_user_current">
              {{ user }}
            </div>
            <div
              v-if="$user.role !== 'STUDENT'"
              class="sb_menu-main_user_current-account-or-role"
            >
              {{ accountOrRole }}
            </div>

            <dropdown-menu slot="list">
              <router-link :to="{ name: 'Profile' }">
                <sb-dropdown-button>
                  <div class="menu-item-with-icon">
                    Mijn profiel
                    <sb-icon icon-id="icon_cog" />
                  </div>
                </sb-dropdown-button>
              </router-link>

              <div class="test">
                <sb-logged-in-account
                  v-for="otherUser in otherUsers"
                  :key="otherUser.username"
                  :user="otherUser"
                  style="width: 100%"
                  @switch-user="$emit('switch-user', $event)"
                />
              </div>
              <button @click="$emit('other-account')">
                <sb-dropdown-button>
                  <div class="menu-item-with-icon">
                    Voeg nieuw account toe
                    <sb-icon icon-id="icon_plus" />
                  </div>
                </sb-dropdown-button>
              </button>
              <button @click="$emit('logout')">
                <sb-dropdown-button>
                  <div class="menu-item-with-icon">
                    Overal uitloggen
                    <sb-icon icon-id="icon_signout" />
                  </div>
                </sb-dropdown-button>
              </button>
            </dropdown-menu>
          </dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import SbBadge from '@/components/SbBadge';
import SbLogoIcon from '@/components/SbLogoIcon';
import BadgeMixin from '@/mixins/badgeMixin.js';
import { getUsers } from '@/lib/user-management';
import SbLoggedInAccount from './SbLoggedInAccount.vue';
import SbIcon from './global/SbIcon.vue';

export default {
  name: 'SbMenuMain',
  components: {
    SbBadge,
    SbLogoIcon,
    SbLoggedInAccount,
    SbIcon,
    SbLoggedInAccount,
  },

  mixins: [BadgeMixin],
  props: {
    /**
     * The current user
     */
    user: {
      type: String,
      default: '',
    },
    /**
     * The current account or role
     */
    accountOrRole: {
      type: String,
      default: '',
    },
    /**
     * The environments for the switcher
     */
    environments: {
      type: Object,
      default: null,
    },
    /**
     * The routes used to build the menu items
     */
    routes: {
      type: Array,
      default() {
        return [];
      },
    },
    currentRoute: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      currentEnvironment: [],
      users: [],
    };
  },
  computed: {
    doRender() {
      if (this.$route.name === 'Session') return false;
      return true;
    },
    otherUsers() {
      return this.users.filter((u) => u.id !== this.$user.id);
    },

    environmentSwitcherData() {
      if (this.$user.role === 'COACH') {
        return [
          ...(this.environments.schools && this.environments.schools.length
            ? this.environments.schools.map((school) => {
                return {
                  value: `school-${school.id}`,
                  label: DOMPurify.sanitize(school.name, {
                    ALLOWED_TAGS: [],
                  }),
                };
              })
            : {}),
        ];
      }
      if (this.$user.role === 'ADMIN') {
        return [
          {
            value: 'admin-default',
            label: 'Admin',
          },
          this.environments.schools && this.environments.schools.length
            ? {
                label: 'Scholen',
                value: 'Scholen',
                children: this.environments.schools.map((school) => {
                  return {
                    value: `school-${school.id}`,
                    label: DOMPurify.sanitize(school.name, {
                      ALLOWED_TAGS: [],
                    }),
                  };
                }),
              }
            : {},
          this.environments.schoolDistricts
            ? {
                label: 'Schooldistricten',
                value: 'Schooldistricten',
                children: this.environments.schoolDistricts.map(
                  (schoolDistrict) => {
                    return {
                      value: `school_district-${schoolDistrict.id}`,
                      label: DOMPurify.sanitize(schoolDistrict.name, {
                        ALLOWED_TAGS: [],
                      }),
                    };
                  },
                ),
              }
            : {},
        ];
      }
      return [];
      // return [
      //   ...this.role === 'ADMIN' ? [
      //     {
      //       value: 'admin',
      //       label: 'Admin'
      //     },
      //   ] : [],
      //   ...this.environments.map((environment) => {
      //     console.log(environment);
      //     const valueObject = {
      //       value: environment.id,
      //       label: environment.name
      //     };
      //     valueObject.children = environment.options && environment.options.length ? environment.options.map((option) => {
      //       return {
      //         value: option.id,
      //         label: option.name
      //       };
      //     }) : [];
      //     return valueObject;
      //   })
      // ];
    },
  },
  watch: {
    $route: {
      handler() {
        this.setSwitcher();
      },
      deep: true,
    },
  },
  mounted() {
    this.setSwitcher();
    this.users = getUsers();
  },
  methods: {
    setSwitcher() {
      if (['default'].includes(this.$route.params.resourceId)) {
        this.currentEnvironment = [
          `${this.$route.params.resourceType}-${this.$route.params.resourceId}`,
        ];
        return;
      }

      this.currentEnvironment = [
        {
          school: 'Scholen',
          school_district: 'Schooldistricten',
        }[this.$route.params.resourceType],
        `${this.$route.params.resourceType}-${this.$route.params.resourceId}`,
      ];
    },
    environment() {
      if (this.$route.meta && this.$route.meta.allowUnauthorized) return '';

      if (this.$user.context.isAdmin) return 'default';
      if (this.$user.role === 'STUDENT') return 'student';
      if (this.$user.role === 'COACH') return 'coaches';
      if (this.$user.context.resourceType === 'SCHOOL_DISTRICT')
        return 'schooldistricts';
      if (this.$user.context.resourceType === 'SCHOOL') return 'schools';
      return this.$user.context.resourceId;
    },
    shouldShow(route) {
      if (!route.meta) return true;
      if (route.meta.hideFromMenu) return false;
      if (route.meta.hideFor) {
        return !route.meta.hideFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role)
            return condition.context === this.$user.context.resourceType;
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      if (route.meta.showFor) {
        return route.meta.showFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role)
            return condition.context === this.$user.context.resourceType;
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
$sb-menu-main-height: 110px;

.sb_menu-main {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $sb-menu-main-height;
  padding: 0 0 0 3rem;

  @media print {
    display: none;
  }

  &_left {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  &_right {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  //
  //  BADGE
  //
  &_badge {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-100%) translateX(100%);
  }

  //
  //  ITEMS
  //

  &_items {
    display: flex;
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
    transition: color 0.3s ease, border-bottom 0.3s ease;

    @media screen and (min-width: 1300px) {
      margin: 0 1.6rem;
    }

    &_btn {
      height: $sb-menu-main-height;
      line-height: $sb-menu-main-height;
      transition: color 0.2s ease;
      color: $brand-black;

      &:hover {
        color: $brand-primary;
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      content: '';
      background: $brand-primary;
      transition: transform 0.3s ease;
      transform: scaleY(0);
    }

    &.s_active {
      &::after {
        transform: scaleY(1);
      }
    }

    &:hover {
      color: $brand-primary;

      &::after {
        transform: scaleY(1);
      }
    }

    &.s_has-dropdown {
      .sb_menu-main_badge {
        transform: translateY(calc(-100% - 6px)) translateX(calc(100% - 24px));
      }
    }

    .ivu-dropdown {
      padding: 0 20px 0 0;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        content: '';
        border-top: 5px solid $brand-gray;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-radius: 4px;
        transform: translateY(-50%);
      }
    }

    &_dropdown {
      &_item {
        position: relative;

        .sb_menu-main_badge {
          transform: translateY(-57%) translateX(-50%) !important;
        }
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    content: '';
    background: $brand-light-gray;
  }

  //
  //  ENVIRONMENT SWITCHER
  //
  &_environment-switcher {
    margin: 0 2rem 0 0;

    .ivu-select-dropdown {
      max-height: 292px !important;
    }

    .ivu-cascader-menu {
      min-width: 210px;
      height: 292px !important;
      padding: 0 !important;
    }

    .ivu-input {
      border-color: $brand-primary;
      border-width: 3px !important;

      &::placeholder {
        color: $sb-input-placeholder-color;
      }
    }
  }

  //
  //  USER
  //
  &_user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: $sb-menu-main-height;
    padding: 0 3rem;
    color: $brand-white;
    text-align: right;
    border-radius: 8px;

    @media screen and (min-width: 1200px) {
      min-width: 320px;
    }

    &_dropdown {
      position: relative;
      user-select: none;
      transition: opacity 0.2s ease;

      &:hover {
        cursor: pointer;

        .sb_menu-main_user_current {
          opacity: 0.8;
        }

        .sb_menu-main_user_current-account-or-role {
          opacity: 0.8;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        content: '';
        border-top: 5px solid currentColor;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-radius: 4px;
        transform: translateY(-50%);
      }

      .ivu-dropdown {
        padding: 0 20px 0 0;
      }

      .ivu-dropdown-rel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: $sb-menu-main-height;
      }
    }

    &_current {
      max-width: 240px;
      overflow: hidden;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_current-account-or-role {
      white-space: nowrap;
      opacity: 0.7;
    }
  }

  &.s_default {
    .sb_menu-main_user {
      background: $brand-env-admin;
      // color: $brand-black;
    }
  }

  &.s_coaches {
    .sb_menu-main_user {
      background: $brand-env-coach;
    }
  }

  &.s_student {
    .sb_menu-main_user {
      background: $brand-env-student;
      color: $brand-black;
    }
  }

  &.s_schools {
    .sb_menu-main_user {
      background: $brand-env-school;
    }
  }

  &.s_schooldistricts {
    .sb_menu-main_user {
      background: $brand-env-schooldistrict;
    }
  }
}

// Custom
.menu-item-with-icon {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
</style>
