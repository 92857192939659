<template>
  <button @click="$emit('switch-user', user.username)">
    <div class="account">
      <span class="account_role">
        {{ role }}
      </span>
      <sb-dropdown-button>
        <span class="account_name">
          {{ user.username }}
        </span>
      </sb-dropdown-button>
    </div>
  </button>
</template>

<script>
export default {
  name: 'SbLoggedInAccount',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    role() {
      const role = this.user.role;
      switch (role) {
        case 'COACH':
          return 'BEGELEIDER';
        case 'SCHOOL_DISTRICT_MANAGER':
          return 'DISTRICT';
        case 'MANAGER':
          return 'BEHEERDER';
        default:
          return role;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  .account_role {
    color: #666;
    font-size: 12px;
    padding-left: 10px;
    padding-top: 5px;
  }

  .account_name {
    color: #666;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 5px;
  }
}
</style>
